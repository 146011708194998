import {
  Button,
  Container,
  FormControl,
  FormLabel,
  Grid2 as Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { useStytch, useStytchUser } from "@stytch/react";
import { StytchAPIError } from "@stytch/vanilla-js";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { createUser } from "settings/api/Users";
import { Loading } from "shared/components/Loading";
import { Toast } from "shared/components/Toast";
import TypeIt from "typeit-react";

const Authentication = () => {
  const stytch = useStytch();
  const { user } = useStytchUser();
  const [openToast, setOpenToast] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [weakPassword, setWeakPassword] = useState(false);

  const handleStytchError = (error: StytchAPIError) => {
    if (error.error_type === "weak_password") {
      setWeakPassword(true);
    } else {
      setErrorMessage(error.error_message);
      setOpenToast(true);
    }
  };

  const onPasswordSet = (newPassword: string) => {
    setNewPassword(newPassword);
  };

  const onPasswordReset = async () => {
    const token = new URLSearchParams(window.location.search).get("token");

    if (token && newPassword) {
      setLoading(true);
      try {
        console.log("starting passwordReset");
        await stytch.passwords.resetByEmail({
          token: token,
          password: newPassword,
          session_duration_minutes: 18 * 60,
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.log(err);
        console.log("Oh no!");
        if (err.error_type) {
          const stytchError = err as StytchAPIError;
          handleStytchError(stytchError);
        } else {
          setErrorMessage(err);
          setOpenToast(true);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (stytch && !user) {
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get("token");
        const tokenType = queryParams.get("stytch_token_type");

        if (token && tokenType) {
          if (tokenType === "magic_links") {
            const result = await stytch.magicLinks.authenticate(token, {
              session_duration_minutes: 60,
            });

            axios.defaults.headers.common = {
              ...axios.defaults.headers.common,
              Authorization: result.session_jwt,
            };

            try {
              await createUser(result.user_id);
            } catch (err) {
              console.log(err);
              setOpenToast(true);
            }

            window.location.replace("/thoughts");
          } else if (tokenType === "reset_password") {
            setIsResetPassword(true);
          }
        }
      } else {
        window.location.replace("/thoughts");
      }
    })();
  }, [stytch, user, setOpenToast, setIsResetPassword]);

  return (
    <Fragment>
      {!isResetPassword && <Loading>Authenticating...</Loading>}
      {isResetPassword && (
        <Container>
          <Grid>
            <Typography variant="h3" align="center">
              Set your new password
            </Typography>
          </Grid>
          <Grid size={12} style={{ margin: "0 auto", height: "60vh" }}>
            <FormControl fullWidth={true}>
              <FormLabel>New Password</FormLabel>
              <TextField
                value={newPassword}
                onChange={(e) => onPasswordSet(e.target.value)}
                placeholder="New password"
                type="password"
              />
              <FormLabel error={weakPassword} hidden={!weakPassword}>
                Sorry, this password is too weak. You could try using a{" "}
                <Link
                  href="https://1password.com/password-generator"
                  color="inherit"
                  display={"inline"}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography display={"inline"}>
                    password generator?
                  </Typography>
                </Link>
              </FormLabel>
            </FormControl>
            <FormControl fullWidth={true}>
              <Button
                variant="contained"
                onClick={onPasswordReset}
                style={{ marginTop: "24px" }}
                disabled={loading}
                type="submit"
              >
                {!loading ? (
                  "Reset"
                ) : (
                  <TypeIt
                    options={{
                      waitUntilVisible: true,
                      loop: true,
                    }}
                  >
                    Resetting...
                  </TypeIt>
                )}
              </Button>
            </FormControl>
          </Grid>
        </Container>
      )}
      <Toast
        message={`Something went wrong while authenticating - ${errorMessage} Please contact us.`}
        open={openToast}
      />
    </Fragment>
  );
};

export default Authentication;
