import {
  Button,
  Container,
  FormControl,
  FormLabel,
  Grid2 as Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useStytch } from "@stytch/react";
import { Fragment, useCallback, useState } from "react";
import TypeIt from "typeit-react";

export const ForgottenPassword: React.FC = () => {
  const { passwords } = useStytch();
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [resetSent, setResetSent] = useState(false);

  const onEmailInput = (email: string) => {
    setEmail(email);
  };

  const resetPasswordStart = useCallback(() => {
    if (email) {
      setLoading(true);
      passwords.resetByEmailStart({
        email,
      });
      setResetSent(true);
      setLoading(false);
    }
  }, [email, passwords]);

  return (
    <Fragment>
      <Container>
        <Grid>
          <Typography variant="h3" align="center">
            Forgot your password?
          </Typography>
        </Grid>
        <Grid size={12} style={{ margin: "0 auto", height: "60vh" }}>
          <FormControl fullWidth={true}>
            <FormLabel>Email</FormLabel>
            <TextField
              value={email}
              onChange={(e) => onEmailInput(e.target.value)}
              placeholder="Email"
              type="email"
              disabled={resetSent}
            />
          </FormControl>
          {resetSent ? (
            <Grid>
              <Typography align="center" style={{ marginTop: "24px" }}>
                Password reset has been sent. Please check your email.
              </Typography>
            </Grid>
          ) : (
            <FormControl fullWidth={true}>
              <Button
                variant="contained"
                onClick={resetPasswordStart}
                style={{ marginTop: "24px" }}
                disabled={loading}
                type="submit"
              >
                {!loading ? (
                  "Send reset request"
                ) : (
                  <TypeIt
                    options={{
                      waitUntilVisible: true,
                      loop: true,
                    }}
                  >
                    Sending...
                  </TypeIt>
                )}
              </Button>
            </FormControl>
          )}
        </Grid>
      </Container>
    </Fragment>
  );
};
